let my_about_card = { 
  width: '18rem',
  textAlign: 'center',
  margin: 'auto',
  border: 'none',
  borderRadius: '0',
  marginBottom: 10,
}

let gray_bg_color = { backgroundColor: '#f1f2f2' }
let white_bg_color = { backgroundColor: '#f8f9fa' }
let rp_pin_blue_bg_color = { backgroundColor: '#1b8bb8' }

let centerText = { textAlign: 'center' }

let carousel_img_style = { maxWidth: 400, maxHeight: 250, width: "auto", height: "auto" }

export {
  my_about_card,
  gray_bg_color,
  white_bg_color,
  centerText,
  rp_pin_blue_bg_color,
  carousel_img_style,
}
