import "bootstrap/dist/css/bootstrap.min.css";
import "./FullPage.css";

import Header from "../../components/header/Header";
import About from "../../components/about/About";
import MyForm from "../../components/form/MyForm";
import Footer from "../../components/footer/Footer";

function App() {
  return (
    <>
      <Header />
      <About />
      <MyForm />
      <Footer />
    </>
  );
}

export default App;
