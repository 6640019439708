import { useState } from "react";
import {
  Card,
  CardColumns,
  Carousel,
  Col,
  Row,
  Container,
  ListGroup,
} from "react-bootstrap";

// import MyBackupForForm from './MyBackupForForm'
import {
  my_about_card,
  gray_bg_color,
  centerText,
  carousel_img_style,
} from "../../utils/stylingConstants";
import "./About.css";

import landing_page from "../../assets/00-landing-page-images/logo-oficina-06-percent.png";
import carousel_1 from "../../assets/03-nuestros-clientes/01.1-cuela.png";
import carousel_coop from "../../assets/03-nuestros-clientes/01.2-coopharma.png";
import carousel_2 from "../../assets/03-nuestros-clientes/02.3-az-foods.png";
import carousel_3 from "../../assets/03-nuestros-clientes/03-new-era.png";
import carousel_4 from "../../assets/03-nuestros-clientes/04-french-tulip.png";
import carousel_5 from "../../assets/03-nuestros-clientes/05-advanced-infusion-services.png";
import carousel_6 from "../../assets/03-nuestros-clientes/06-cortes-chocolate.png";
import carousel_7 from "../../assets/03-nuestros-clientes/07-insight-group.png";
import carousel_8 from "../../assets/03-nuestros-clientes/08-plaza-loiza.png";
import carousel_9 from "../../assets/03-nuestros-clientes/09-bodegas-compostela.png";
import carousel_10 from "../../assets/03-nuestros-clientes/10-pharmacon.png";
import carousel_11 from "../../assets/03-nuestros-clientes/11-la-prena.png";
import carousel_12 from "../../assets/03-nuestros-clientes/12-sol-de-hoy.png";
import carousel_13 from "../../assets/03-nuestros-clientes/13-pepe-ganga.jpg";
import carousel_14 from "../../assets/03-nuestros-clientes/02.4-green-bites.png";
import one_two_hour from "../../assets/01-opciones-de-entrega/1-2-hour.png";
import same_day from "../../assets/01-opciones-de-entrega/same-day.png";
import next_day from "../../assets/01-opciones-de-entrega/next-day.png";
import como_funciona_1 from "../../assets/02-como-funciona/1-como-funciona.png";
import como_funciona_2 from "../../assets/02-como-funciona/2-como-funciona.png";
import como_funciona_3 from "../../assets/02-como-funciona/3-como-funciona.png";
import como_funciona_4 from "../../assets/02-como-funciona/4-como-funciona.png";
import como_funciona_5 from "../../assets/02-como-funciona/5-como-funciona.png";

const carouselItems = [
  // 1. Advanced, 2. Pepe Ganga, 3. New Era, 4. Cuela
  { alt: "Advanced Infusion Services logo", img: carousel_5 },
  { alt: "Pepe Ganga", img: carousel_13 },
  { alt: "New Era Health logo", img: carousel_3 },
  { alt: "Green Bites logo", img: carousel_14 },
  { alt: "Cuela logo", img: carousel_1 },
  { alt: "Coop Harma logo", img: carousel_coop },
  { alt: "AZ Foods logo", img: carousel_2 },
  { alt: "French Tulip logo", img: carousel_4 },
  { alt: "Cortes Chocolate logo", img: carousel_6 },
  { alt: "Insight Group PR logo", img: carousel_7 },
  { alt: "Plaza Loíza logo", img: carousel_8 },
  { alt: "Bodegas Compostela logo", img: carousel_9 },
  { alt: "PharMaCon logo", img: carousel_10 },
  { alt: "La Preñá logo", img: carousel_11 },
  { alt: "Al Sol De Hoy logo", img: carousel_12 },
];

function About() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => setIndex(selectedIndex);

  return (
    <>
      <Card style={gray_bg_color}>
        <Card.Body>
          <h1 className="Center Heading">
            Solución de entregas SAME DAY para negocios.
          </h1>
          <p className="Center Subheading">
            ¡Ofrécele a tus clientes una opción más rápida de entregas!
          </p>
        </Card.Body>
        <br />

        <img src={landing_page} className="landing-page-img" />
        <br />
        <br />
        <br />

        <Card.Body>
          <Card.Title className="Heading max-width-container">
            Algunos de nuestros clientes
          </Card.Title>

          <Carousel
            className="Center"
            activeIndex={index}
            onSelect={handleSelect}
          >
            {carouselItems.map((item) => (
              <Carousel.Item>
                <img src={item.img} alt={item.alt} style={carousel_img_style} />
              </Carousel.Item>
            ))}
          </Carousel>
          <br />
          <br />
          <br />

          <Card.Title className="Heading">
            Puedes escoger entre las siguientes opciones de entregas para tu
            empresa
            <br />
            <br />
          </Card.Title>
          <CardColumns style={centerText}>
            <Card style={my_about_card}>
              <Card.Img src={one_two_hour} alt="1-2 hour delivery" />
              <Card.Text className="Subheading">• Entrega en 1-2 hrs</Card.Text>
            </Card>
            <Card style={my_about_card}>
              <Card.Img src={same_day} alt="Same Day delivery" />
              <Card.Text className="Subheading">
                • Entrega ese mismo día en la tarde
              </Card.Text>
            </Card>
            <Card style={my_about_card}>
              <Card.Img src={next_day} alt="Next Day delivery" />
              <Card.Text className="Subheading">
                • Entrega a más tardar al fin del próximo día
              </Card.Text>
            </Card>
          </CardColumns>
          <br />
          <br />
          <br />

          <Card.Title className="Heading">¿Cómo funciona?</Card.Title>
          <Container>
            <Row style={{ marginBottom: 10 }}>
              <Col style={centerText}>
                <Card style={my_about_card}>
                  <Card.Img variant="top" src={como_funciona_1} />
                  <Card.Text className="Subheading">
                    1. Nos conectamos a tu sistema (de forma automatizada o
                    manual) para recibir las órdenes que entregaremos
                  </Card.Text>
                </Card>
              </Col>

              <Col>
                <Card style={my_about_card}>
                  <Card.Img variant="top" src={como_funciona_2} />
                  <Card.Text className="Subheading">
                    2. Recibimos su orden una vez sea sometida al sistema.
                  </Card.Text>
                </Card>
              </Col>

              <Col>
                <Card style={my_about_card}>
                  <Card.Img variant="top" src={como_funciona_3} />
                  <Card.Text className="Subheading">
                    3. Se asigna un conductor que recogerá la orden en su
                    localidad.
                  </Card.Text>
                </Card>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col>
                <Card style={my_about_card}>
                  <Card.Img variant="top" src={como_funciona_4} />
                  <Card.Text className="Subheading">
                    4. Se envía mensaje automatizado a su cliente una vez
                    recibimos la orden y también cuando el driver esté de camino
                    a entregar.
                  </Card.Text>
                </Card>
              </Col>

              <Col>
                <Card style={my_about_card}>
                  <Card.Img variant="top" src={como_funciona_5} />
                  <Card.Text className="Subheading">
                    5. Una vez la orden sea entregada usted recibirá
                    notificación de que la orden fue completada.
                  </Card.Text>
                </Card>
              </Col>
            </Row>
          </Container>

          <br />
          <br />
          <br />

          <Card.Title className="Heading">
            Beneficios de RonponEntregas
          </Card.Title>
          <ListGroup variant="flush" className="Subheading">
            <ListGroup.Item style={gray_bg_color}>
              ¡Entregarás más rapido que nadie!
            </ListGroup.Item>
            <ListGroup.Item style={gray_bg_color}>
              Costo fijo y económico por entrega.
            </ListGroup.Item>
            <ListGroup.Item style={gray_bg_color}>
              Equipo de operación y servicio al cliente monitoreando en todo
              momento el buen funcionamiento de tus entregas.
            </ListGroup.Item>
            <ListGroup.Item style={gray_bg_color}>
              Comunicación automatizada con tu cliente sobre el status de su
              entrega.
            </ListGroup.Item>
          </ListGroup>

          <br />
          <br />
          <br />

          <Card.Title id="contacto" className="Heading">
            Solicita el servicio de RonponEntregas para tu negocio online o
            físico
          </Card.Title>
          <Card.Text className="Subheading">
            Una vez someta la información nos estaremos comunicando con usted
            para una reunión de orientación.
            <br />
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default About;
