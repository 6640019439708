import React from 'react';
import ReactDOM from 'react-dom';
import FullPage from './pages/home/FullPage';

ReactDOM.render(
  <React.StrictMode>
    <FullPage />
  </React.StrictMode>,
  document.getElementById('root')
);
