import { Navbar } from "react-bootstrap";
import "./Footer.css";

function MyFooter() {
  return (
    <Navbar className="myFooter-bg-color">
      <Navbar.Text
        className="Center Ronpon-gray m-auto myFooter-bg-color"
        style={{ color: "#f1f2f2" }}
      >
        RonponEntregas © {new Date().getFullYear()}, una división de{" "}
        <a
          href="https://ronponpr.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ronpon, LLC
        </a>
      </Navbar.Text>
    </Navbar>
  );
}

export default MyFooter;
