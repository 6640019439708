import { Navbar, Image } from "react-bootstrap";
import { Link } from "react-scroll";
import "./Header.css";
import logo_transparente from "../../assets/logo-pics/logo-transparente-cropped.png";

const Header = () => (
  <header className="header-container">
    <Navbar>
      <Image
        className="header-logo"
        src={logo_transparente}
        alt="Ronpon Entregas logo"
      />

      <Navbar.Text className="ml-auto Subheading header-bg">
        <Link to="contacto" smooth={true} duration={1000}>
          Contáctenos
        </Link>
      </Navbar.Text>
    </Navbar>
  </header>
);

export default Header;
