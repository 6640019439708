import { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form, Card, Spinner } from "react-bootstrap";
import Reaptcha from "reaptcha";
import emailjs from "emailjs-com";

import {
  centerText,
  rp_pin_blue_bg_color,
  gray_bg_color,
} from "../../utils/stylingConstants";

const styling = { padding: "1.25rem", maxWidth: "50rem" };

// (XXX) XXX-XXXX OR (XXX)XXX-XXXX OR XXXXXXXXXX OR XXXXXXXXXXX
const phoneNumReg =
  /^((\([0-9]{3}\) |[0-9]{3}-|\([0-9]{3}\))[0-9]{3}-[0-9]{4})|[0-9]{10}|[0-9]{11}$/;

const schema = yup.object().shape({
  name: yup.string().required("Por favor provea el nombre de su empresa."),
  email: yup
    .string()
    .email("Por favor provea un correo electrónico válido.")
    .required("Por favor provea un correo electrónico."),
  phone: yup
    .string()
    .matches(phoneNumReg, "Por favor provea un número de telefono válido.")
    .min(10, "Por favor provea un número de teléfono válido.")
    .max(14, "Por favor provea un número de teléfono válido.")
    .required("Por favor provea un número de teléfono de contacto."),
  description: yup
    .string()
    .required("Por favor provea una descripción breve de su empresa."),
});

class MyForm extends Component {
  constructor() {
    super();

    this.state = {
      isRecaptchaVerified: true,
      isSending: false,
      formSent: false,
    };

    this.onloadCallback = this.onloadCallback.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.sendEmailJS = this.sendEmailJS.bind(this);
  }

  onloadCallback() {
    console.log("reCAPTCHA has loaded.");
  }

  verifyCallback(response) {
    if (response) {
      this.setState({ isRecaptchaVerified: true });
    }
    // console.log(response);
  }

  sendEmailJS(errors, isValid, values) {
    // console.log(errors);
    // console.log(isValid);
    // console.log(values);
    // console.log(this.state);

    if (
      JSON.stringify(errors) !== JSON.stringify({}) ||
      (isValid !== undefined && !isValid) ||
      !this.state.isRecaptchaVerified
    ) {
      return;
    }

    emailjs
      .send(
        "service_emg84m4",
        "template_lv16wnd",
        values,
        "user_yrFtprBSSzbOcdxi8Nelk"
      )
      .then(
        (result) => {
          this.setState({ formSent: true });
        },
        (error) => {
          console.log(error.text);
        }
      );

    this.setState({ isSending: true });
  }

  render() {
    if (this.state.formSent || this.state.isSending) {
      return (
        <Card
          style={{
            ...styling,
            ...gray_bg_color,
            border: "none",
            borderRadius: "0",
          }}
        >
          {this.state.formSent ? (
            <Card.Title className="Subheading">
              Nos estaremos comunicando con usted pronto.
            </Card.Title>
          ) : (
            <>
              <Card.Title
                style={{ textAlign: "center" }}
                className="Subheading"
              >
                Enviando su información...
              </Card.Title>
              <Spinner
                style={{ ...centerText, margin: "auto" }}
                animation="border"
                role="status"
              ></Spinner>
            </>
          )}
        </Card>
      );
    }

    return (
      <Formik
        validationSchema={schema}
        onSubmit={console.log}
        initialValues={{
          name: "",
          email: "",
          phone: "",
          description: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form
            noValidate
            style={styling}
            className="Subheading"
            id="ronponEntregasSubmissionForm"
          >
            <Form.Group controlId="validationFormik01">
              <Form.Label>Nombre de su empresa</Form.Label>
              <Form.Control
                type="text"
                placeholder="Juan del Pueblo, Inc."
                name="name"
                value={values.name}
                onChange={handleChange}
                isInvalid={!!errors.name}
              />

              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationFormik02">
              <Form.Label>Correo eléctronico</Form.Label>
              <Form.Control
                type="email"
                placeholder="juan@delpueblo.com"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationFormik03">
              <Form.Label>Número de teléfono contacto</Form.Label>
              <Form.Control
                type="text"
                placeholder="(787) 444-5555"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                isInvalid={!!errors.phone}
              />

              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationFormik04">
              <Form.Label>Descripción corta de su empresa</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={5}
                name="description"
                value={values.description}
                onChange={handleChange}
                isInvalid={!!errors.description}
              />

              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form>
              <Reaptcha
                sitekey="6LcZXKIaAAAAAMj-sUvLK97u1lckdMm6TBtGwm7X"
                render="explicit"
                onLoad={this.onloadCallback}
                onVerify={this.verifyCallback}
              ></Reaptcha>
            </Form>

            <Button
              fullWidth={true}
              onClick={() => this.sendEmailJS(errors, isValid, values)}
              style={{ ...rp_pin_blue_bg_color, marginTop: 10 }}
            >
              Someter
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default MyForm;
